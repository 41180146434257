var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('main',{staticClass:"main",class:this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'},[_c('div',{staticClass:"content container-fluid academy"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(0),_c('div',{staticClass:"col-12"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/Retail_Clients"}},[_vm._v("რითეილ კლიენტები")])],1),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v("დეტალები")])])])]),_c('div',{staticClass:"col-12 mt-3"},[_c('div',{staticClass:"card"},[_vm._m(1),_c('div',{staticClass:"card-body"},[_c('table',{staticClass:"table table-borderless"},[_c('tbody',[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('tr',[_c('td',{staticClass:"pt-3"},[_vm._m(7),_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":"/Deals"}},[_c('i',{staticClass:"fa fa-eye"}),_vm._v(" დილების ნახვა")])],1)])])])])])])])]),_c('div',{staticClass:"footer",class:this.$store.state.showTopMenu== true ? 'collapsed' : ''},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":"/Retail_Clients"}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" უკან ")])],1)])])]),_vm._m(8)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"page-title mb-1"},[_vm._v("დავით კენჭაძე")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header bg-custom-primary d-flex justify-content-between text-white"},[_c('span',{staticClass:"font-weigt-bold"},[_vm._v("კლიენტის მონაცემები")]),_c('span',{staticClass:"font-weigt-bold"},[_vm._v("ID: 50")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-weigt-bold mb-2"},[_vm._v("დავით კენჭაძე")]),_vm._v(" 577 135 135  /  577 135 135 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"d-flex flex-column py-3"},[_c('span',{staticClass:"font-weigt-bold mb-2"},[_vm._v("ელ.ფოსტა")]),_vm._v(" dk@dkcapital.ge ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"d-flex flex-column py-3"},[_c('span',{staticClass:"font-weigt-bold mb-2"},[_vm._v("პირადი ნომერი")]),_vm._v(" 01010101011 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"d-flex flex-column py-3"},[_c('span',{staticClass:"font-weigt-bold mb-2"},[_vm._v("კლიენტის რეგისტრაციის ავტორი")]),_vm._v(" სალომე ჩემია ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"d-flex flex-column py-3"},[_c('span',{staticClass:"font-weigt-bold mb-2"},[_vm._v("რეგისტრაციის თარიღი")]),_vm._v(" 01.01.2022 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"btn btn-secondary me-1",attrs:{"href":"client-data.html"}},[_c('i',{staticClass:"tio-edit"}),_vm._v(" შეცვლა")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"id":"clientHiostory","tabindex":"-1","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered modal-dialog-scrollable"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v("დილების ისტორია")]),_c('button',{staticClass:"btn-close",attrs:{"type":"button","data-bs-dismiss":"modal","aria-label":"Close"}})]),_c('div',{staticClass:"modal-body"},[_vm._v(" ... ")]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-bs-dismiss":"modal"}},[_vm._v("დახურვა")])])])])])
}]

export { render, staticRenderFns }