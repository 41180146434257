<template>
  <div>
    <Header/>
    <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <div class="content container-fluid academy">
        <!-- Row -->
        <div class="row align-items-center">


          <div class="col-8 mb-1">
            <h1 class="page-title">რითეილ კლიენტები</h1>
          </div>

          <div class="col-4 text-end mb-1">
            <div class="btn btn-light" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRetailClientsRight" aria-controls="offcanvasRight"> <span class="d-none d-md-inline-block">ფილტრი</span> <i class="tio-search"></i> </div>
          </div>

          <!-- Col -->
          <div class="col-12 col-md-6 mt-4">
            <div class="card">
              <div class="card-body">
                <p class="text-muted">სულ რითეილ კლიენტები</p>
                <h3><i class="tio-group-equal text-primary"></i> 50</h3>
              </div>
            </div>
          </div>
          <!-- End Col -->

          <!-- Col -->
          <div class="col-12 col-md-6 mt-4">
            <div class="card">
              <div class="card-body">
                <p class="text-muted">აქტიური რითეილ კლიენტები</p>
                <h3><i class="tio-group-equal text-custom-success"></i> 30</h3>
              </div>
            </div>
          </div>
          <!-- End Col -->

          <!-- Col -->
          <div class="col-12 mt-4">
            <div class="card card-hover-shadow">
              <div class="card-body">
                <div class="row">
                  <div class="col-5 col-md-2 d-flex align-items-center">
                    <i class="tio-circle text-success me-3"></i>
                    <h6 class="me-3 mb-0"> ID: 50</h6>
                  </div>
                  <div class="col-7 col-md-10">
                    <div class="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-md-between">
                      <div>
                        <a class="font-weigt-bold" href="/Retail_Client_Details">დავით კენჭაძე</a><br>
                        <span>dk@dkcapital.ge</span>
                        <span class="d-block d-md-none">577 135 135  -  577 135 135</span>
                      </div>
                      <span class="d-none d-md-block">577 135 135  -  577 135 135</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End Col -->

          <!-- Col -->
          <div class="col-12 mt-3">
            <div class="card card-hover-shadow">
              <div class="card-body">
                <div class="row">
                  <div class="col-5 col-md-2 d-flex align-items-center">
                    <i class="tio-circle text-danger me-3"></i>
                    <h6 class="me-3 mb-0"> ID: 49</h6>
                  </div>
                  <div class="col-7 col-md-10">
                    <div class="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-md-between">
                      <div>
                        <a class="font-weigt-bold" href="/Retail_Client_Details">სალომე ჩემია</a><br>
                        <span>s.chemia@dkcapital.ge</span>
                        <span class="d-block d-md-none">558 141 344  -  558 141 344</span>
                      </div>
                      <span class="d-none d-md-block">558 141 344  -  558 141 344</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End Col -->

          <!-- Col -->
          <div class="col-12 mt-3">
            <div class="card card-hover-shadow">
              <div class="card-body">
                <div class="row">
                  <div class="col-5 col-md-2 d-flex align-items-center">
                    <i class="tio-circle text-success me-3"></i>
                    <h6 class="me-3 mb-0"> ID: 48</h6>
                  </div>
                  <div class="col-7 col-md-10">
                    <div class="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-md-between">
                      <div>
                        <a class="font-weigt-bold" href="/Retail_Client_Details">ნინო მდივანი</a><br>
                        <span>n.mdivani@dkcapital.ge</span>
                        <span class="d-block d-md-none">555 311 787  -  555 311 787</span>
                      </div>
                      <span class="d-none d-md-block">555 311 787  -  555 311 787</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End Col -->

        </div>
        <!-- End Row -->
      </div>
      <!-- End Content -->

      <!-- Footer -->
      <div class="footer" :class="this.$store.state.showTopMenu== true ? 'collapsed' : ''">
        <div class="row">
          <div class="col">
            <router-link to="/">
              <a href="/"><i class="fa fa-arrow-left"></i> უკან</a>
            </router-link>
          </div>
        </div>
      </div>
      <!-- End Footer -->

    </main>
    <!-- End Main -->


    <!-- offcanvas Ritail Clients Filter -->
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRetailClientsRight" aria-labelledby="offcanvasRightLabel">
      <div class="offcanvas-header border-bottom">
        <h4 id="offcanvas-title">ფილტრი</h4>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <form class="row">
          <div class="col-12 form-group">
            <label class="h5">სახელი</label>
            <input class="form-control" type="text" placeholder="სახელი">
          </div>
          <div class="col-12 form-group">
            <label class="h5">გვარი</label>
            <input class="form-control" type="text" placeholder="გვარი">
          </div>
          <div class="col-12 form-group">
            <label class="h5">ტელეფონის ნომერი</label>
            <input class="form-control" type="number" placeholder="ტელეფონის ნომერი">
          </div>
          <div class="col-12 form-group">
            <label class="h5">პირადი მენეჯერი</label>
            <v-select placeholder="აირჩიე პირადი მენეჯერი" :options="[
              'მენეჯერი 1',
              'მენეჯერი 2',
              'მენეჯერი 3'
            ]"></v-select>
          </div>
          <div class="col-12 form-group">
            <label class="h5">ფილიალის დირექტორი</label>
            <v-select placeholder="აირჩიე პირადი მენეჯერი" :options="[
              'სალომე ჩემია',
              'ნინო მალანია',
              'ეკა მოლაშვილი'
            ]"></v-select>
          </div>
          <div class="col-12 form-group">
            <label class="h5">ID</label>
            <input class="form-control" type="number" placeholder="ID">
          </div>
          <div class="col-12 form-group">
            <label class="h5">კლიენტის ელ.ფოსტა</label>
            <input class="form-control" type="number" placeholder="კლიენტის ელ.ფოსტა">
          </div>
          <div class="col-12 form-group">
            <label class="h5">კლიენტის სტატუსი</label>
            <v-select placeholder="აირჩიე პირადი მენეჯერი" :options="[
              'აქტიური',
              'პასიური'
            ]"></v-select>
          </div>
          <div class="col-12 form-group">
            <label class="h5">კლიენტის პირადი ნომერი</label>
            <input class="form-control" type="number" placeholder="კლიენტის პირადი ნომერი">
          </div>
        </form>
      </div>
      <div class="offcanvas-footer p-3 border-top bg-white">
        <div class="row">
          <div class="col-6">
            <button type="button" class="btn btn-block btn-primary"><i class="fa fa-search me-1"></i> შესრულება</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-block btn-white"><i class="tio-remove-from-trash text-secondary me-1"></i> გასუფთავება</button>
          </div>
        </div>
      </div>
    </div>
    <!-- End offcanvas Ritail Clients Filter -->

  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: "Retail_Clients",
  components: {
    Header
  }
}
</script>

<style scoped>

</style>