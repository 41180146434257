<template>
  <div>
    <Header/>
    <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <div class="content container-fluid academy">
        <!-- Row -->
        <div class="row align-items-center">


          <div class="col-8">
            <h1 v-if="this.$route.params.status == 'liked'" class="page-title mb-1">მოწონებული</h1>
            <h1 v-if="this.$route.params.status == 'meetings'" class="page-title mb-1">საპრეზენტაციო</h1>
            <h1 v-if="this.$route.params.status == 'offers'" class="page-title mb-1">განსახილველი</h1>
            <h1 v-if="this.$route.params.status == 'looking_for'" class="page-title mb-1">შეთავაზების გარეშე</h1>
            <h1 v-if="this.$route.params.status == 'won'" class="page-title mb-1">გაყიდული</h1>
            <h1 v-if="this.$route.params.status == 'lost'" class="page-title mb-1">დაკარგული</h1>
          </div>

          <div class="col-4 text-end">
            <router-link :to="`/Retail_Client_Information/`+this.$route.params.id">
              <div class="btn btn-light"> <span class="d-none d-md-inline-block">პარამეტრები</span> <i class="tio-filter-outlined"></i> </div>
            </router-link>
          </div>

          <div class="col-12 mb-2 d-none d-md-block">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/Deals_Overview">ჩემი დილები</router-link></li>
                <li class="breadcrumb-item">
                  <router-link v-if="this.$route.params.status == 'liked'" :to="`/Deals/`+this.$route.params.status">მოწონებული დილები</router-link>
                  <router-link v-if="this.$route.params.status == 'meetings'" :to="`/Deals/`+this.$route.params.status">საპრეზენტაციო დილები</router-link>
                  <router-link v-if="this.$route.params.status == 'offers'" :to="`/Deals/`+this.$route.params.status">განსახილველი დილები</router-link>
                  <router-link v-if="this.$route.params.status == 'looking_for'" :to="`/Deals/`+this.$route.params.status">შეთავაზების გარეშე დილები</router-link>
                  <router-link v-if="this.$route.params.status == 'won'" :to="`/Deals/`+this.$route.params.status">გაყიდული დილები</router-link>
                  <router-link v-if="this.$route.params.status == 'lost'" :to="`/Deals/`+this.$route.params.status">დაკარგული დილები</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">დავით კენჭაძე</li>
              </ol>
            </nav>
          </div>

          <div class="col-12 mb-2 d-block d-md-none">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link v-if="this.$route.params.status == 'liked'" :to="`/Deals/`+this.$route.params.status">მოწონებული დილები</router-link>
                  <router-link v-if="this.$route.params.status == 'meetings'" :to="`/Deals/`+this.$route.params.status">საპრეზენტაციო დილები</router-link>
                  <router-link v-if="this.$route.params.status == 'offers'" :to="`/Deals/`+this.$route.params.status">განსახილველი დილები</router-link>
                  <router-link v-if="this.$route.params.status == 'looking_for'" :to="`/Deals/`+this.$route.params.status">შეთ. გარეშე დილები</router-link>
                  <router-link v-if="this.$route.params.status == 'won'" :to="`/Deals/`+this.$route.params.status">გაყიდული დილები</router-link>
                  <router-link v-if="this.$route.params.status == 'lost'" :to="`/Deals/`+this.$route.params.status">დაკარგული დილები</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">დავით კენჭაძე</li>
              </ol>
            </nav>
          </div>

          <!-- Col -->
          <div class="col-12 mb-4">
            <div class="card">
              <div class="card-body d-flex justify-content-between align-items-center">
                 <span>
                  <h5 class="card-header-title">კლიენტი</h5>
                  <h5 class="card-header-title font-weight-normal mt-2">დავით კენჭაძე</h5>
                </span>

                <div class="d-flex">
                  <a href="tel:+995577135135" class="btn btn-custom-success me-1" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top"><i class="fa fa-phone"></i> <span class="d-none d-md-inline-block">დარეკვა</span></a>
                  <button type="button" class="btn btn-outline-light text-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="tio-more-vertical"></i>
                  </button>
                  <ul class="dropdown-menu text-start py-2 rounded-3 border-light" style="box-shadow: 0 10px 40px 10px rgb(140 152 164 / 18%); z-index: 1;">
                    <div class="list-group">
                      <label class="h5 ps-3">ქმედება</label>
                      <hr class="mx-0 my-0">
                      <button type="button" class="list-group-item list-group-item-action border-0 rounded-0 font-size-sm text-custom-danger px-4" data-bs-toggle="modal" data-bs-target="#lostDeal"><i class="tio-appointment-cancelled"></i> დაკარგვა</button>
                      <button type="button" class="list-group-item list-group-item-action border-0 rounded-0 font-size-sm px-4" data-bs-toggle="modal" data-bs-target="#dealsLog"><i class="tio-archive"></i> ლოგი</button>
                    </div>
                    <hr class="mx-0 my-0">
                    <div class="list-group">
                      <div class="px-3 py-2">
                        <label class="h5">პირადი მენეჯერი</label><br>
                        <small>სალომე ჩემია</small><br>
                      </div>
                    </div>
                  </ul>
                </div>

              </div>
            </div>
          </div>
          <!-- End Col -->

          <div class="col-12">
            <div class="row">
              <!-- Closing -->
              <div class="col-6 mt-4 mt-md-2">
                  <router-link :to="`/Offers_Details`">
                  <div class="card border-success bg-custom-success card-hover-shadow">
                    <div class="card-body py-2 py-md-4">
                      <div class="row align-items-center gx-2">
                        <div class="col text-center text-md-start">
                          <i class="tio-thumbs-up font-weight-normal display-4 text-white me-2"></i>
                          <span class="display-4 text-white">1</span>
                        </div>
                        <div class="col-auto d-none d-md-block">
                      <span class="h5 text-white">
                        მოწონებული - უძ/ქ
                      </span>
                        </div>
                      </div>
                      <!-- End Row -->
                    </div>
                  </div>
                </router-link>
              </div>
              <!-- End Closing -->

              <!-- Reject -->
              <div class="col-6 mt-4 mt-md-2">
                <router-link :to="`/Offers_Details`">
                  <div class="card border-danger bg-custom-danger card-hover-shadow">
                    <div class="card-body py-2 py-md-4">
                      <div class="row align-items-center gx-2">
                        <div class="col text-center text-md-start">
                          <i class="tio-thumbs-down font-weight-normal display-4 text-white me-2"></i>
                          <span class="display-4 text-white">3</span>
                        </div>
                        <div class="col-auto d-none d-md-block">
                      <span class="h5 text-white">
                        უარყოფილი - უძ/ქ
                      </span>
                        </div>
                      </div>
                      <!-- End Row -->
                    </div>
                  </div>
                </router-link>
              </div>
              <!-- End Reject -->
            </div>
          </div>

          <div class="col-12 mt-4">
            <div class="row">
              <!-- Presentation -->
              <div class="col-12 mt-4 mt-md-4">
                <router-link :to="`/Offers_Details`">
                  <div class="card border-custom-hover card-hover-shadow h-100">
                    <div class="card-body">
                      <div class="row align-items-center gx-2">
                        <div class="col text-dark">
                          <i class="tio-calendar-month font-weight-normal display-4 text-primary me-2"></i>
                          <span class="display-4">5</span>
                        </div>
                        <div class="col-auto text-dark">
                      <span class="h5">
                        <span>საპრეზენტაციო</span> - უძ/ქ
                      </span>
                        </div>
                      </div>
                      <!-- End Row -->
                    </div>
                  </div>
                </router-link>
              </div>
              <!-- End Presentation -->

              <!-- Active Offers -->
              <div class="col-12 mt-2">
                <router-link :to="`/Offers_Details`+'/'+this.$route.params.id">
                  <div class="card border-custom-hover card-hover-shadow h-100">
                    <div class="card-body">
                      <div class="row align-items-center gx-2">
                        <div class="col text-dark">
                          <i class="tio-arrow-drop-up-circle-outlined font-weight-normal display-4 me-2 text-custom-warning"></i>
                          <span class="display-4">10</span>
                        </div>
                        <div class="col-auto">
                      <span class="h5 text-dark">
                       <span> გადასარჩევი</span>- უძ/ქ
                      </span>
                        </div>
                      </div>
                      <!-- End Row -->
                    </div>
                  </div>
                </router-link>
              </div>
              <!-- End Active Offers -->

            </div>
          </div>

        </div>
        <!-- End Row -->
      </div>
      <!-- End Content -->

      <!-- Footer -->
      <div class="footer" :class="this.$store.state.showTopMenu== true ? 'collapsed' : ''">
        <div class="row">
          <div class="col">
            <router-link :to="`/Deals/`+this.$route.params.status">
              <i class="fa fa-arrow-left"></i> უკან
            </router-link>
          </div>
        </div>
      </div>
      <!-- End Footer -->

    </main>
    <!-- End Main -->

    <!-- offcanvas Ritail Clients Filter -->
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasClosingDeals" aria-labelledby="offcanvasRightLabel">
      <div class="offcanvas-header border-bottom">
        <h4 id="offcanvas-title">ფილტრი</h4>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <form class="row">
          <div class="col-12 form-group">
            <label class="h5">პროდუქტის ტიპი </label>
            <v-select placeholder="აირჩიე პროექტის ტიპი" :options="[
              'ბინები',
              'სასტუმროები',
              'კომერციული ფართები',
              'მიწის ნაკვეთები',
              'სახლები და აგარაკები'
            ]"></v-select>
          </div>
          <div class="col-12 form-group">
            <label class="h5">უძრავი ქონების სტატუსი </label>
            <v-select placeholder="უძრავი ქონების სტატუსი" :options="[
              'ახალი აშენებული',
              'მშენებარე',
              'ძველი აშენებული'
            ]"></v-select>
          </div>
          <div class="col-12 form-group">
            <label class="h5">უძრავი ქონების კონდიცია </label>
            <v-select placeholder="უძრავი ქონების კონდიცია" :options="[
              'თეთრი კარკასი',
              'შავი კარკასი',
              'მწვანე კარკასი',
              'ახალი რემონტი',
              'ძველი რემონტი'
            ]"></v-select>
          </div>
          <div class="col-12 form-group">
            <label class="h5">საძინებლების რაოდენობა </label>
            <v-select multiple placeholder="აირჩიე საძინებლების რაოდენობა" :options="[
              0,
              1,
              2,
              3,
              4
            ]"></v-select>
          </div>
          <div class="col-12">
            <label class="h5">ფასი </label>
          </div>
          <div class="col-6 form-group">
            <label>მინიმალური($)</label>
            <input type="number" class="form-control" placeholder="-დან">
          </div>
          <div class="col-6 form-group">
            <label>მაქსიმალური($)</label>
            <input type="number" class="form-control" placeholder="-მდე">
          </div>
          <div class="col-12">
            <label class="h5">ფართის მოცულობა </label>
          </div>
          <div class="col-6 form-group">
            <label>მინიმალური</label>
            <input type="number" class="form-control" placeholder="-დან">
          </div>
          <div class="col-6 form-group">
            <label>მაქსიმალური</label>
            <input type="number" class="form-control" placeholder="-მდე">
          </div>

        </form>
      </div>
      <div class="offcanvas-footer p-3 border-top bg-white">
        <div class="row">
          <div class="col-6">
            <button type="button" class="btn btn-block btn-primary"><i class="fa fa-search me-1"></i> შესრულება</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-block btn-white"><i class="tio-remove-from-trash text-secondary me-1"></i> გასუფთავება</button>
          </div>
        </div>
      </div>
    </div>
    <!-- End offcanvas Ritail Clients Filter -->

  <!-- Lost Client -->
  <div class="modal fade" id="lostDeal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">დილის დაკარგვა</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-custom-danger">
          ნამდვილად გსურთ კლიენტის დაკარგვა?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">გაუქმება</button>
          <button type="button" class="btn btn-custom-danger">დაკარგვა</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Deal's Log -->
  <div class="modal fade" id="dealsLog" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">დილის ლოგი</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          დილის ლოგი
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">დახურვა</button>
        </div>
      </div>
    </div>
  </div>

  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: "Details",
  components: {
    Header
  },
  mounted() {
   
  },
}
</script>

<style scoped>
.border-custom-hover {
  border: 1px solid #d5d5d5 !important;
  transition: 0.3s;
}
.border-custom-hover:hover {
  border: 1px solid #727a86 !important;
}
</style>