<template>
  <div>
    <Header/>
    <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <div class="content container-fluid academy">
        <!-- Row -->
        <div class="row align-items-center">


          <div class="col-8">
            <h1 v-if="this.$route.params.param == 'liked'" class="page-title mb-1">მოწონებული</h1>
            <h1 v-if="this.$route.params.param == 'meetings'" class="page-title mb-1">საპრეზენტაციო</h1>
            <h1 v-if="this.$route.params.param == 'offers'" class="page-title mb-1">განსახილველი</h1>
            <h1 v-if="this.$route.params.param == 'looking_for'" class="page-title mb-1">შეთავაზების გარეშე</h1>
            <h1 v-if="this.$route.params.param == 'won'" class="page-title mb-1">გაყიდული</h1>
            <h1 v-if="this.$route.params.param == 'lost'" class="page-title mb-1">დაკარგული</h1>
          </div>

          <div class="col-4 text-end">
            <div class="btn btn-light" data-bs-toggle="offcanvas" data-bs-target="#offcanvasClosingDeals" aria-controls="offcanvasRight"> <span class="d-none d-md-inline-block">ფილტრი</span> <i class="tio-search"></i> </div>
          </div>

          <div class="col-12  mb-2">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/Deals_Overview">ჩემი დილები</router-link></li>
                <li v-if="this.$route.params.param == 'liked'" class="breadcrumb-item active" aria-current="page">მოწონებული დილები</li>
                <li v-if="this.$route.params.param == 'meetings'" class="breadcrumb-item active" aria-current="page">საპრეზენტაციო დილები</li>
                <li v-if="this.$route.params.param == 'offers'" class="breadcrumb-item active" aria-current="page">განსახილველი დილები</li>
                <li v-if="this.$route.params.param == 'looking_for'" class="breadcrumb-item active" aria-current="page">შეთავაზების დილები</li>
                <li v-if="this.$route.params.param == 'won'" class="breadcrumb-item active" aria-current="page">გაყიდული დილები</li>
                <li v-if="this.$route.params.param == 'lost'" class="breadcrumb-item active" aria-current="page">დაკარგული დილები</li>
              </ol>
            </nav>
          </div>

          <!-- Deals Col -->
          <div class="col-12 col-md-6 mt-2" v-for="item in loadDeals.data" :key="item.id">
  
            <router-link :to="`/Details/`+item.deal_status+'/'+item.id">
            <div class="card border-custom-hover">
              <div class="card-header bg-light d-flex justify-content-between align-items-center py-2" >
                <div class="text-dark">
                  <span class="h5 me-2">ID: {{item.id}}</span>
                  <i class="tio-user-big"></i> <span class="font-weigt-bold">{{ item.ClientDetail.name +' '+ item.ClientDetail.surname  }} </span>
                </div>
                <span v-if="item.deal_status == 'liked'" class="badge bg-custom-success text-white">მოწონებული</span>
                <span v-if="item.deal_status == 'meetings'" class="badge bg-custom-primary">საპრეზენტაციო</span>
                <span v-if="item.deal_status == 'offers'" class="badge bg-custom-warning text-dark">განსახილველი</span>
                <span v-if="item.deal_status == 'looking_for'" class="badge bg-custom-danger text-white">შეთ. გარეშე</span>
                <span v-if="item.deal_status == 'won'" class="badge bg-custom-success text-white">გაყიდული</span>
                <span v-if="item.deal_status == 'lost'" class="badge bg-custom-danger text-white">დაკარგული</span>
              </div>
              <div class="card-body">
                <!-- Row -->
                <div class="row">
                  <!-- Col -->
                  <div class="col-6 ">
                    <span class="h5 font-weigt-bold"><i class="tio-dollar-outlined"></i> 
                        {{ Number(item.minimum_amount).toLocaleString()+'$'+ ' - ' + Number(item.maximum_amount).toLocaleString()+'$' }}</span>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="col-6 mb-2 text-muted text-end">
                    <span>{{ item.CreatedDate }}</span>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="col-7">
                    <span class="h5 font-weigt-bold"><i class="tio-shopping-cart"></i> {{ item.ProductType.name }} - {{ item.RealEstateType.name }}</span>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="col-5 text-muted text-end">
                    <span>{{ item.OwnerDetail.name}}</span>
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End Row -->
              </div>
            </div>
            </router-link>
          </div>
          <!-- End Deals Col -->

          <!-- Deals Col -->
          <div class="col-12 col-md-6 mt-2 d-none">
            <router-link to="/Deal_Details">
            <div class="card border-custom-hover">
              <div class="card-header bg-light d-flex justify-content-between align-items-center py-2" >
                <div class="text-dark">
                  <span class="h5 me-2">ID: 51</span>
                  <i class="tio-user-big"></i> <span class="font-weigt-bold">დავით კენჭაძე </span>
                </div>
                <span class="badge bg-custom-primary text-white">საპრეზენტაციო</span>
              </div>
              <div class="card-body">
                <!-- Row -->
                <div class="row">
                  <!-- Col -->
                  <div class="col-6">
                    <span class="h5 font-weigt-bold"><i class="tio-dollar-outlined"></i> 2 000 000 $</span>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="col-6 mb-2 text-muted text-end">
                    <span>01.01.2022</span>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="col-6">
                    <span class="h5 font-weigt-bold"><i class="tio-shopping-cart"></i> ყიდვა - ბინა</span>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="col-6 text-muted text-end">
                    <span>სალომე ჩემია</span>
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End Row -->
              </div>
            </div>
            </router-link>
          </div>
          <!-- End Deals Col -->

          <!-- Deals Col -->
          <div class="col-12 col-md-6 mt-2 d-none">
            <router-link to="/Deal_Details">
            <div class="card border-custom-hover">
              <div class="card-header bg-light d-flex justify-content-between align-items-center py-2" >
                <div class="text-dark">
                  <span class="h5 me-2">ID: 51</span>
                  <i class="tio-user-big"></i> <span class="font-weigt-bold">დავით კენჭაძე </span>
                </div>
                <span class="badge bg-custom-warning text-dark">განსახილველი</span>
              </div>
              <div class="card-body">
                <!-- Row -->
                <div class="row">
                  <!-- Col -->
                  <div class="col-6">
                    <span class="h5 font-weigt-bold"><i class="tio-dollar-outlined"></i> 2 000 000 $</span>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="col-6 mb-2 text-muted text-end">
                    <span>01.01.2022</span>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="col-6">
                    <span class="h5 font-weigt-bold"><i class="tio-shopping-cart"></i> ყიდვა - ბინა</span>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="col-6 text-muted text-end">
                    <span>სალომე ჩემია</span>
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End Row -->
              </div>
            </div>
            </router-link>
          </div>
          <!-- End Deals Col -->

          <!-- Deals Col -->
          <div class="col-12 col-md-6 mt-2 d-none">
            <router-link to="/Deal_Details">
            <div class="card border-custom-hover">
              <div class="card-header bg-light d-flex justify-content-between align-items-center py-2" >
                <div class="text-dark">
                  <span class="h5 me-2">ID: 51</span>
                  <i class="tio-user-big"></i> <span class="font-weigt-bold">დავით კენჭაძე </span>
                </div>
                <span class="badge bg-custom-danger text-white">შეთ. გარეშე</span>
              </div>
              <div class="card-body">
                <!-- Row -->
                <div class="row">
                  <!-- Col -->
                  <div class="col-6">
                    <span class="h5 font-weigt-bold"><i class="tio-dollar-outlined"></i> 2 000 000 $</span>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="col-6 mb-2 text-muted text-end">
                    <span>01.01.2022</span>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="col-6">
                    <span class="h5 font-weigt-bold"><i class="tio-shopping-cart"></i> ყიდვა - ბინა</span>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="col-6 text-muted text-end">
                    <span>სალომე ჩემია</span>
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End Row -->
              </div>
            </div>
            </router-link>
          </div>
          <!-- End Deals Col -->

          <!-- Deals Col -->
          <div class="col-12 col-md-6 mt-2 d-none">
            <router-link to="/Deal_Details">
              <div class="card border-custom-hover">
                <div class="card-header bg-light d-flex justify-content-between align-items-center py-2" >
                  <div class="text-dark">
                    <span class="h5 me-2">ID: 51</span>
                    <i class="tio-user-big"></i> <span class="font-weigt-bold">დავით კენჭაძე </span>
                  </div>
                  <span class="badge bg-custom-success text-white">გაყიდული</span>
                </div>
                <div class="card-body">
                  <!-- Row -->
                  <div class="row">
                    <!-- Col -->
                    <div class="col-6">
                      <span class="h5 font-weigt-bold"><i class="tio-dollar-outlined"></i> 2 000 000 $</span>
                    </div>
                    <!-- End Col -->

                    <!-- Col -->
                    <div class="col-6 mb-2 text-muted text-end">
                      <span>01.01.2022</span>
                    </div>
                    <!-- End Col -->

                    <!-- Col -->
                    <div class="col-6">
                      <span class="h5 font-weigt-bold"><i class="tio-shopping-cart"></i> ყიდვა - ბინა</span>
                    </div>
                    <!-- End Col -->

                    <!-- Col -->
                    <div class="col-6 text-muted text-end">
                      <span>სალომე ჩემია</span>
                    </div>
                    <!-- End Col -->
                  </div>
                  <!-- End Row -->
                </div>
              </div>
            </router-link>
          </div>
          <!-- End Deals Col -->

          <!-- Deals Col -->
          <div class="col-12 col-md-6 mt-2 d-none">
            <router-link to="/Deal_Details">
            <div class="card border-custom-hover">
              <div class="card-header bg-light d-flex justify-content-between align-items-center py-2" >
                <div class="text-dark">
                  <span class="h5 me-2">ID: 51</span>
                  <i class="tio-user-big"></i> <span class="font-weigt-bold">დავით კენჭაძე </span>
                </div>
                <span class="badge bg-custom-danger text-white">დაკარგული</span>
              </div>
              <div class="card-body">
                <!-- Row -->
                <div class="row">
                  <!-- Col -->
                  <div class="col-6">
                    <span class="h5 font-weigt-bold"><i class="tio-dollar-outlined"></i> 2 000 000 $</span>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="col-6 mb-2 text-muted text-end">
                    <span>01.01.2022</span>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="col-6">
                    <span class="h5 font-weigt-bold"><i class="tio-shopping-cart"></i> ყიდვა - ბინა</span>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="col-6 text-muted text-end">
                    <span>სალომე ჩემია</span>
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End Row -->
              </div>
            </div>
            </router-link>
          </div>
          <!-- End Deals Col -->


        </div>
        <!-- End Row -->
      </div>
      <!-- End Content -->

      <div class="col-md-12 mt-4 mb-5">
          <div class="pagination-container">
            <pagination :limit="3" show-disabled :data="loadDeals" @pagination-change-page="LoadDeals">
              <template #prev-nav>
                <span>წინ</span>
              </template>
              <template #next-nav>
                <span>შემდეგ</span>
              </template>
            </pagination>
          </div>
        </div>

      <!-- Footer -->
      <div class="footer" :class="this.$store.state.showTopMenu== true ? 'collapsed' : ''">
        <div class="row">
          <div class="col">
            <router-link to="/Deals_Overview">
              <i class="fa fa-arrow-left"></i> უკან
            </router-link>
          </div>
        </div>
      </div>
      <!-- End Footer -->

    </main>
    <!-- End Main -->

    <!-- offcanvas Ritail Clients Filter -->
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasClosingDeals" aria-labelledby="offcanvasRightLabel">
      <div class="offcanvas-header border-bottom">
        <h4 id="offcanvas-title">ფილტრი</h4>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <form class="row">
          <div class="col-12 form-group">
            <label class="h5">დილის ID</label>
            <input class="form-control" type="number" placeholder="დილის ID">
          </div>
          <div class="col-12 form-group">
            <label class="h5">პროდუქტის ტიპი </label>
            <v-select placeholder="აირჩიე პროექტის ტიპი" :options="[
              'ბინები',
              'სასტუმროები',
              'კომერციული ფართები',
              'მიწის ნაკვეთები',
              'სახლები და აგარაკები'
            ]"></v-select>
          </div>
          <div class="col-12 form-group">
            <label class="h5">უძრავი ქონების სტატუსი </label>
            <v-select placeholder="უძრავი ქონების სტატუსი" :options="[
              'ახალი აშენებული',
              'მშენებარე',
              'ძველი აშენებული'
            ]"></v-select>
          </div>
          <div class="col-12 form-group">
            <label class="h5">უძრავი ქონების კონდიცია </label>
            <v-select placeholder="უძრავი ქონების კონდიცია" :options="[
              'თეთრი კარკასი',
              'შავი კარკასი',
              'მწვანე კარკასი',
              'ახალი რემონტი',
              'ძველი რემონტი'
            ]"></v-select>
          </div>
          <div class="col-12 form-group">
            <label class="h5">საძინებლების რაოდენობა </label>
            <v-select multiple placeholder="აირჩიე საძინებლების რაოდენობა" :options="[
              0,
              1,
              2,
              3,
              4
            ]"></v-select>
          </div>
          <div class="col-12">
            <label class="h5">ფასი </label>
          </div>
          <div class="col-6 form-group">
            <label>მინიმალური($)</label>
            <input type="number" class="form-control" placeholder="-დან">
          </div>
          <div class="col-6 form-group">
            <label>მაქსიმალური($)</label>
            <input type="number" class="form-control" placeholder="-მდე">
          </div>
          <div class="col-12">
            <label class="h5">ფართის მოცულობა </label>
          </div>
          <div class="col-6 form-group">
            <label>მინიმალური</label>
            <input type="number" class="form-control" placeholder="-დან">
          </div>
          <div class="col-6 form-group">
            <label>მაქსიმალური</label>
            <input type="number" class="form-control" placeholder="-მდე">
          </div>

          <div class="col-12 form-group">
            <label class="h5">ფილიალის დირექტორი </label>
            <v-select multiple placeholder="აირჩიე ფილიალის დირექტორი" :options="[
              'სალომე ჩემია',
              'ეკა მოლაშვილი',
              'ნინო მდივანი'
            ]"></v-select>
          </div>
          <div class="col-12 form-group">
            <label class="h5">გაყიდვების მენეჯერი </label>
            <v-select multiple placeholder="აირჩიე გაყიდვების მენეჯერი" :options="[
              'მენეჯერი 1',
              'მენეჯერი 2',
              'მენეჯერი 3'
            ]"></v-select>
          </div>

        </form>
      </div>
      <div class="offcanvas-footer p-3 border-top bg-white">
        <div class="row">
          <div class="col-6">
            <button type="button" class="btn btn-block btn-primary"><i class="fa fa-search me-1"></i> შესრულება</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-block btn-white"><i class="tio-remove-from-trash text-secondary me-1"></i> გასუფთავება</button>
          </div>
        </div>
      </div>
    </div>
    <!-- End offcanvas Ritail Clients Filter -->

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import axios from 'axios';

export default {
  name: "Closing",
  data() {
    return {
      loadDeals: {
        data: {}
      },
      test: {}
    }
  },
  components: {
    Header
  },
  methods: {
    LoadDeals(page = 1){
      
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/deal/getDeals/'+this.$route.params.param+'?page='+page,{
        headers: {
          'Authorization': `Bearer ${token}`
        },
        // params:{
        //   name:this.$route.query.name,
        //   id:this.$route.query.id,
        //   position:this.$route.query.position,
        //   region:this.$route.query.region,
        //   min_budget:this.$route.query.min_budget,
        //   max_budget:this.$route.query.max_budget,
        //   start_at:this.$route.query.start_at,
        //   end_at:this.$route.query.end_at,
        //   payment_status:this.$route.query.payment_status
        // }
      })
          .then(response => {
            window.scroll(0, 0);
            this.loadDeals=response.data;

         
            this.isLoading = false;
          });
    },

  },
  mounted() {
    this.LoadDeals();

  },
}
</script>

<style scoped>
.border-custom-hover {
  border: 1px solid #d5d5d5 !important;
  transition: 0.3s;
}
.border-custom-hover:hover {
  border: 1px solid #727a86 !important;
}
</style>